



























































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { TipoDeOperacao } from '@/models/Recurso'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import { AcaoDeCashback } from '@/models/AcaoDeCashback'
import SeletorDeGruposEconomicos from "@/components/loja/SeletorDeGruposEconomicos.vue"
import { SaveCashbackUseCase } from '@/usecases'
import DatePicker from '@/components/ui/DatePicker.vue'
import moment from "moment"


@Component({
	components: {
		SeletorDeLojasDoUsuario, 
		SeletorDeGruposEconomicos,
		DatePicker,
	},
})

export default class DialogoDeEdicaoDeAcaoDeCashback extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNomeAcao!: HTMLInputElement

	acaoDeCashback: AcaoDeCashback | null = null
	obrigatorio = obrigatorio
	mostra = false
	criandoNovaAcao = false
	salvando = false
	saveCashbackUseCase = new SaveCashbackUseCase()

	mostrar(acaoDeCashback: AcaoDeCashback | null, criandoNovaAcao: boolean ) {
		this.acaoDeCashback = JSON.parse(JSON.stringify(acaoDeCashback))
		this.mostra = true
		this.criandoNovaAcao = criandoNovaAcao

		setTimeout(() => {
			this.form.resetValidation()
			this.campoDeNomeAcao.focus()
		})
	}

	esconder() {
		this.acaoDeCashback = null
		this.mostra = false
	}

	get podeAlterarAcao() {
		return this.podeRealizarOperacao('alterar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'acao-de-cashback'))
	}

	gerarDataHora(data?: string | null, hora?: string | null): string {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	async salvar(){
		if (!this.acaoDeCashback) return
		if (!this.form.validate()) return
		try {
			if(!this.acaoDeCashback.grupoEconomico){
				AlertModule.setError('Obrigatório informar grupo econômico')
			}
			this.salvando = true
			this.acaoDeCashback.dataInicio = this.gerarDataHora(this.acaoDeCashback.dataInicio, '00:00')
			this.acaoDeCashback.dataFim = this.gerarDataHora(this.acaoDeCashback.dataFim, '23:59')
			const acaoSalva = !this.acaoDeCashback.id
				? await this.saveCashbackUseCase.criarAcaoDeCashback(this.acaoDeCashback)
				: await this.saveCashbackUseCase.atualizarAcaoDeCashback(this.acaoDeCashback)

			this.$emit('confirmado', acaoSalva)
			this.mostra = false
			AlertModule.setSuccess('Ação de Cashback salva com sucesso')
		}catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.$emit('sucesso')
		}
	}
}
